// lib
@use "./assets/scss/reset.scss";
@use "./assets/scss/bootstrap-pre.scss";
@use 'bootstrap/scss/bootstrap';
@use "./assets/scss/bootstrap-post.scss";
@use 'ngx-toastr/toastr';
@use "./assets/scss/helpers.scss";
@use "./assets/scss/recaptcha.scss";
@use "./assets/scss/fonts.scss";
@use "./assets/scss/variables.scss";
@use "./assets/scss/tooltips.scss";
@use "./assets/scss/icons.scss";
@use "./assets/scss/tables.scss";
@use "./assets/scss/fields.scss";
@use "./assets/scss/buttons.scss";
@use "./assets/scss/modals.scss";
@use "./assets/scss/popovers.scss";
@use "./assets/scss/toasts.scss";
@use "./assets/scss/stamps.scss";
@use "./assets/scss/page.scss";
@use "./assets/scss/richtext.scss";
@use "./assets/scss/animations.scss";
@use "./assets/scss/shared.scss";

// version
._version{
    position: fixed;
    bottom: 6px;
    right: 8px;
    z-index: 999999;
    font-size: 10px;
    text-transform: uppercase;
    color: #fff;
    background-color: #999999;
    padding: 5px 7px;
    padding-top: 6px;
    border-radius: 4px;
    opacity: 0.2;
    transition: 0.1s ease;
    padding-left: 23px;
    background-image: url(/assets/img/icons/_version.svg);
    background-size: 13px;
    background-position: center left 6px;
    background-repeat: no-repeat;
    cursor: pointer;
    &:hover{
        opacity: 1;
    }
}

// index
body {
    word-break: break-word;
    color: var(--text-color);
    font-family: 'proxima-nova', sans-serif;
    font-size: 15px;
    line-height: 25px;
    font-weight: 400;
    display: flex;
    flex-direction: column;
    min-height: 100dvh;
    background-color: #F7F7F7;
    &.body--admin{
        background-color: #fff;
    }
}
app-root{
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

// general
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration { display: none; }
a{
    color: var(--primary-blue);
}
.focusable, a, button{
    outline: unset;
}