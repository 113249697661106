:root{
    --primary-color: #2EA1F8;
    --primary-blue: #2EA1F8;
    --primary-gray: #8E8E8E;

    --medbook-blue-10-pct: #EAF6FE;
    --medbook-blue-5-pct: #F5FAFF;
    --medbook-blue: #2EA1F8;
    --medbook-blue-10-pct: #EAF6FE;
    --medbook-blue-5-pct: #F5FAFF;

    --text-color: #252525;
    --field-border-color: #E5E9EB;
    --default-outline: none;

    --assistant-color: #2EA1F8;
    --supervisor-color: #34C78E;
    --staff-color: #C52233;
    --admin-color: #0E79B2;

    --assistant-color--5-pct: #F5FAFF;
    --supervisor-color--5-pct: #E9F4EC;
    --staff-color--5-pct: #FFF4F5;
    --admin-color--5-pct: #EAF6FE;

    --medbook-red: #F82E2E;
    --medbook-green: #3DAC64;
    --medbook-orange: #FD9800;

    --medbook-orange-5-pct: #FFF3DF;
    --medbook-orange-10-pct: #FFECD1;
    --medbook-green-5-pct: #EFF5F1;
    --medbook-green-10-pct: #DCF0E3;

    .body--assistant{
        --primary-color: #2EA1F8;
        --primary-color--hover: #2991DF;
        --primary-color--active: #0E79B2;
    }
    .body--supervisor{
        --primary-color: #34C78E;
        --primary-color--hover: #0C7C59;
        --primary-color--active: #0C7C59;
    }
    .body--staff{
        --primary-color: #C52233;
        --primary-color--hover: #A51C30;
        --primary-color--active: #A51C30;
    }
    .body--admin{
        --primary-color: #0E79B2;
        --primary-blue: #0E79B2;
        --primary-gray: #91A2AF;
        .row{ --bs-gutter-x: 10px; }
    }
}

.color--assistant{ color: var(--assistant-color); }
.color--supervisor{ color: var(--assistant-color); }
.color--staff{ color: var(--staff-color); }
.color--admin{ color: var(--admin-color); }

.background-color--assistant{ background-color: var(--assistant-color); }
.background-color--supervisor{ background-color: var(--assistant-color); }
.background-color--staff{ background-color: var(--staff-color); }
.background-color--admin{ background-color: var(--admin-color); }