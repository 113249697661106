.toasts--sticky{
    #toast-container{
        position: fixed;
        top: 0;
    }
}
#toast-container{
    position: absolute;
    top: 80px;
    padding-top: 10px;
    .ngx-toastr{
        box-shadow: none;
        padding: 10px 20px;
        padding-left: 50px;
        width: max-content;
        max-width: min(calc(100vw - 40px), 600px);
        border-radius: 4px;
        margin: 0;
        margin-inline: auto;
        margin-top: 10px;
        .toast-close-button{
            span{
                display: none;
            }
        }
        // &.toast-warning{
        //     background-image: url(/assets/img/components/toastr/warning.svg);
        //     background-repeat: no-repeat;
        //     background-size: 20px 20px;
        //     background-position: center left 16px;
        // }
        &.toast-error{
            background-image: url(/assets/img/components/toastr/error.svg);
            background-repeat: no-repeat;
            background-size: 20px 20px;
            background-position: center left 16px;
            background-color: var(--medbook-red);
        }
        &.toast-info{
            background-image: url(/assets/img/components/toastr/info.svg);
            background-repeat: no-repeat;
            background-size: 20px 20px;
            background-position: center left 16px;
            background-color: #1079B2;
        }
        &.toast-success{
            background-image: url(/assets/img/components/toastr/success.svg);
            background-repeat: no-repeat;
            background-size: 20px 20px;
            background-position: center left 16px;
            background-color: var(--medbook-green);
        }
    }
    .toast-title{
        font-weight: 600;
    }
    .toast-message{
        color: #fff;
        font-size: 15px;
        line-height: 20px;
        font-weight: 500;
    }
}

// admin overrides
.body--admin{
    #toast-container{
        top: 24px;
    }
}