// ngb
.tooltip{
    font-family: 'proxima-nova', sans-serif;
    &.tooltip--simple{
        --bs-tooltip-max-width: 340px;
        --bs-tooltip-color: #fff;
        --bs-tooltip-bg: rgba(44, 44, 44, 0.85);
        --bs-tooltip-border-radius: 8px;
        --bs-tooltip-opacity: 1;
        --bs-tooltip-arrow-width: 18px;
        --bs-tooltip-arrow-height: 8px;
        .tooltip-arrow{
            display: none;
        }
        .tooltip-inner{
            padding: 5px 10px;
            border-radius: 4px;
            font-size: 14px;
            line-height: 20px;
            font-weight: 400;
            color: #fff !important;
        }
    }
    &.tooltip--reference-list{
        --bs-tooltip-max-width: 340px;
        --bs-tooltip-color: var(--text-color);
        --bs-tooltip-bg: #fff;
        --bs-tooltip-border-radius: 4px;
        --bs-tooltip-opacity: 1;
        // --bs-tooltip-arrow-width: 18px;
        // --bs-tooltip-arrow-height: 8px;
        min-width: 260px;
        .tooltip-arrow{
            display: none;
        }
        .tooltip-inner{
            display: flex;
            flex-direction: column;
            font-size: 15px;
            line-height: 20px;
            text-align: left;
            border-radius: 4px;
            box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.15);
            padding: 0;
            .reference-wrap{
                padding: 8px 10px;
                display: grid;
                grid-template-columns: 1fr 1fr;
                gap: 10px;
                border-bottom: 1px solid #E5E9EB;
                &:last-of-type{
                    border-bottom: none;
                }
                &>div{

                }
            }
        }
    }
}

// custom popper (EllipsisTooltipDirective)
.ellipsis-tooltip{
    max-width: 340px;
    color: #fff;
    background-color: rgba(44, 44, 44, 0.85);
    padding: 5px 10px;
    border-radius: 4px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    padding: 5px 10px;
    z-index: 9999;
    opacity: 0;
    pointer-events: none;
    transition: opacity .2s ease;
}
.ellipsis-tooltip[data-show] {
    opacity: 1;
    pointer-events: all;
}