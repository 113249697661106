.page{
    flex-grow: 1;
    position: relative;
    padding: 20px 0;
    --page-block-padding-x: 40px;
    --page-block-padding-y: 20px;
    .page-block{
        padding: var(--page-block-padding-y) var(--page-block-padding-x);
        background-color: #fff;
        border-left: 1px solid #E5E9EB;
        border-right: 1px solid #E5E9EB;
        border-top: 1px solid #E5E9EB;
        &:first-of-type{
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
        }
        &:not( :has(+ .page-block) ){
            border-bottom: 1px solid #E5E9EB;
            border-bottom-left-radius: 4px;
            border-bottom-right-radius: 4px;
            // border-top-left-radius: 0;
            // border-top-right-radius: 0;
        }
        &.page-block--space-y-40{
            padding-top: 40px;
            padding-bottom: 40px;
        }
        &.page-block--space-t-40{
            padding-top: 40px;
        }
        &.page-block--space-b-40{
            padding-top: 40px;
        }
        &:last-of-type:not(.page-block--has-after, .page-block--form-button-row){
            padding-bottom: 40px;
        }
        &.page-block--form-button-row{
            padding-bottom: var(--page-block-padding-y);
        }
        &.page-block--has-after{
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }
        &.page-block--is-last{
            border-bottom-left-radius: 4px;
            border-bottom-right-radius: 4px;
            border-bottom: 1px solid #E5E9EB;
        }
        &.page-block--has-before{
            border-top: none;
            border-top-left-radius: 0;
            border-top-right-radius: 0;
        }
    }
    .page-block-spacer{
        height: 30px;
        & + .page-block{
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
        }
    }
    .page-block-tabs-wrap{
        margin-left: calc(var(--page-block-padding-x) * -1);
        margin-right: calc(var(--page-block-padding-x) * -1);
        padding-left: var(--page-block-padding-x);
        padding-right: var(--page-block-padding-x);
        border-bottom: 1px solid #E5E9EB;
        app-tabs{
            margin-bottom: -1px;
            display: block;
        }
    }
}

// block head
.page-block-head{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    h2{
        line-height: 22px;
    }
    .title-crumbs-wrap{
        display: flex;
        flex-direction: column;
        gap: 3px;
    }
}

h2{
    font-size: 21px;
    font-weight: 500;
    line-height: 25px;
}

// admin
.page-admin{
    flex-grow: 1;
    position: relative;
    padding: 30px 20px;
    max-width: calc(1920px - 57px);
}
// admin head
.page-top-wrap{
    &.page-top-wrap--detail{
        margin-bottom: 20px;
    }
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    .page-head{
        display: flex;
        justify-content: space-between;
        .left{
            display: flex;
            flex-direction: column;
            gap: 5px;
            h1{
                font-size: 21px;
                font-weight: 500;
                line-height: 22px;
                display: flex;
                flex-wrap: wrap;
                gap: 5px;
                &>span.total-rows{
                    margin-top: 1px;
                    margin-bottom: 1px;
                    height: 20px;
                    background-color: var(--medbook-blue-10-pct);
                    border-radius: 8px;
                    padding: 0 8px;
                    color: #0E79B2;
                    font-weight: 500;
                    font-size: 11px;
                    line-height: 20px;
                    text-transform: uppercase;
                }
            }
            app-breadcrumbs{
                
            }
        }
        .right{
            .buttons{
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                gap: 10px;
                margin: 4px 0;
            }
        }
    }
    .filters-wrap{
        display: flex;
        justify-content: space-between;
        gap: 40px;
        .filters{
            display: flex;
            flex-wrap: wrap;
            gap: 10px;
            &>button.reset-filters{
                height: 36px;
            }
        }
    }
}

// public
.page-public{
    flex-grow: 1;
    position: relative;
    padding: 20px 0;
}

@media screen and (max-width: 600px) {
    // public
    body .page-public.page-responsive{
        padding: 0 !important;
        .page-block{
            border-radius: 0;
            padding: 20px !important;
            border-left: unset;
            border-right: unset;
            &:first-of-type{
                border-top: unset;
            }
            &:last-of-type{
                border-bottom: unset;
            }
        }
    }
}