.popover{
    font-family: 'proxima-nova', sans-serif;
    --bs-popover-font-size: 15px;
    line-height: 25px;
}

.popover--actions{
    border-radius: 4px;
    border: none;
    box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.15);
    background-color: #fff;
    min-width: 0;
    overflow: hidden;
    --bs-popover-max-width: 320px;
    .popover-arrow{
        display: none;
    }
    .popover-body{
        overflow-y: auto;
        padding: 0;
        .options{
            display: flex;
            flex-direction: column;
            .option{
                padding: 10px;
                font-size: 15px;
                line-height: 20px;
                position: relative;
                display: flex;
                align-items: center;
                gap: 7px;
                text-decoration: none;
                border-bottom: 1px solid #E5E9EB;
                color: #252525;
                cursor: pointer;
                &:hover{
                    background-color: var(--medbook-blue-5-pct);
                }
                &:last-of-type{
                    border-bottom: none;
                }
                i{

                }
                span{
                    display: inline-block;
                    transform: translateY(.5px);
                }
            }
        }
    }
}

.popover--notifications{
    border-radius: 4px;
    border: none;
    box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.15);
    background-color: #fff;
    width: 350px;
    max-width: 350px;
    .popover-arrow{
        display: none !important;
    }
    .popover-body{
        padding: 0;
        color: var(--text-color);
        cursor: default;
        &>.title{
            font-size: 17px;
            line-height: 25px;
            padding-left: 15px;
            padding-right: 15px;
            padding-top: 15px;
            margin-bottom: 3px;
        }
        &>.empty-state{
            color: #b2b2b2;
            padding-left: 15px;
            padding-right: 15px;
            padding-bottom: 15px;
        }
        &>.notifications-list{
            overflow-y: auto;
            max-height: 419px;
            // padding-bottom: 15px;
            display: flex;
            flex-direction: column;
            .notification-item{
                padding: 12px 15px;
                text-decoration: none;
                color: var(--text-color);
                display: flex;
                position: relative;
                border-bottom: 1px solid transparent;
                gap: 10px;
                &:hover{
                    background-color: #f8f8f8;
                }
                &::after{
                    content: '';
                    height: 1px;
                    background-color: #E5E9EB;
                    position: absolute;
                    bottom: -1px;
                    left: 15px;
                    right: 15px;
                }
                &:last-of-type{
                    border-bottom: none;
                    &::after{
                        display: none;
                    }
                }
                .left{
                    display: inline-flex;
                    app-avatar{
                        display: inline-flex;
                    }
                }
                .right{
                    display: flex;
                    flex-direction: column;
                    flex-grow: 1;
                    gap: 2px;
                    .name-wrap{
                        display: flex;
                        gap: 10px;
                        line-height: 18px;
                        font-size: 13px;
                        .name{

                        }
                        .ts{
                            font-size: 10px;
                            margin-left: auto;
                            color: #b2b2b2;
                        }
                    }
                    .descr-wrap{
                        display: flex;
                        gap: 10px;
                        line-height: 16px;
                        font-size: 13px;
                        // transform: translateY(1px);
                        .descr{
                            color: #909090;
                            word-break: break-word;
                        }
                        .unread{
                            margin-left: auto;
                            margin-top: 5px;
                            margin-bottom: 5px;
                            width: 6px;
                            height: 6px;
                            border-radius: 99px;
                            background-color: #F82E2E;
                            flex-shrink: 0;
                        }
                    }
                }
            }
        }
    }
}

.popover--profile{
    border-radius: 4px;
    border: none;
    box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.15);
    background-color: #fff;
    width: 100%;
    max-width: 420px;
    margin-top: -50px !important;
    margin-right: -24px !important;
    margin-bottom: 24px;
    .popover-arrow{
        display: none !important;
    }
    .popover-body{
        padding: 0;
        color: var(--text-color);
        position: relative;
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding-top: 20px;
        padding-bottom: 10px;
        button.close{
            position: absolute;
            top: 28px;
            right: 20px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            padding: 10px;
            margin: -10px;
            i{
                flex-shrink: 0;
                display: inline-block;
                background-repeat: no-repeat;
                background-size: contain;
                background-position: center;
                background-image: url(/assets/img/icons/chev-up-profile.svg);
                width: 16px;
                height: 16px;
            }
        }
        .profile-wrap{
            display: flex;
            align-items: center;
            gap: 8px;
            padding: 0 20px;
            app-avatar{

            }
            .user-info{
                display: flex;
                flex-direction: column;
                justify-content: center;
                flex-grow: 1;
                height: 40px;
                max-width: 100%;
                padding-right: 30px;
                width: 0;
                .title, .descr{
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    width: 100%;
                }
                .title{
                    font-size: 15px;
                    line-height: 17px;
                    font-weight: 500;
                    margin-bottom: 1px;
                }
                .descr{
                    font-size: 12px;
                    line-height: 12px;
                }
            }
        }
        .line{
            position: relative;
            border-bottom: 1px solid transparent;
            &::after{
                content: '';
                height: 1px;
                background-color: #E5E9EB;
                position: absolute;
                bottom: -1px;
                left: 20px;
                right: 20px;
                z-index: 1;
            }
        }
        .popover-title{
            padding: 5px 20px;
            color: var(--primary-gray);
            font-size: 10px;
            line-height: 18px;
            text-transform: uppercase;
            font-weight: 500;
        }
        a.popover-link, button.popover-link{
            font-size: 15px;
            line-height: 20px;
            display: flex;
            color: var(--text-color);
            text-decoration: none;
            width: 100%;
            &:hover .legacy-link-inner{
                background-color: #f8f8f8;
            }
            .legacy-link-inner{
                padding: 10px 20px;
                display: flex;
                gap: 10px;
                justify-content: space-between;
                width: 100%;
            }
        }
        .accounts-list{
            display: flex;
            flex-direction: column;
            gap: 10px;
            padding: 0 20px;
            max-height: 278px;
            overflow-y: auto;
            a.account-button{
                text-decoration: none;
                padding: 10px;
                border: 1px solid #E5E9EB;
                border-radius: 4px;
                display: flex;
                gap: 10px;
                align-items: flex-start;
                &.account-button--assistant{
                    svg path{ fill: var(--assistant-color); }
                    &.active{
                        border-color: var(--assistant-color);
                        .radio{
                            border-color: var(--assistant-color);
                            &::after{
                                background-color: var(--assistant-color);
                            }
                        }
                    }
                    &:hover, &.active{
                        background-color: var(--assistant-color--5-pct);
                    }
                }
                &.account-button--supervisor{
                    svg path{ fill: var(--supervisor-color); }
                    &.active{
                        border-color: var(--supervisor-color);
                        .radio{
                            border-color: var(--supervisor-color);
                            &::after{
                                background-color: var(--supervisor-color);
                            }
                        }
                    }
                    &:hover, &.active{
                        background-color: var(--supervisor-color--5-pct);
                    }
                }
                &.account-button--staff{
                    svg path{ fill: var(--staff-color); }
                    &.active{
                        border-color: var(--staff-color);
                        .radio{
                            border-color: var(--staff-color);
                            &::after{
                                background-color: var(--staff-color);
                            }
                        }
                    }
                    &:hover, &.active{
                        background-color: var(--staff-color--5-pct);
                    }
                }
                &.account-button--admin{
                    svg path{ fill: var(--admin-color); }
                    &.active{
                        border-color: var(--admin-color);
                        .radio{
                            border-color: var(--admin-color);
                            &::after{
                                background-color: var(--admin-color);
                            }
                        }
                    }
                    &:hover, &.active{
                        background-color: var(--admin-color--5-pct);
                    }
                }
                i.radio{
                    flex-shrink: 0;
                    width: 16px;
                    height: 16px;
                    border: 1px solid #91A2AF;
                    border-radius: 100%;
                    background-color: #fff;
                    position: relative;
                    margin-top: 2px;
                    margin-bottom: 2px;
                    &::after{
                        content: '';
                        width: 10px;
                        height: 10px;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        translate: -50% -50%;
                        border-radius: 100%;
                    }
                }
                .info-wrap{
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: flex-start;
                    text-align: left;
                    gap: 4px;
                    flex-grow: 1;
                    .title{
                        width: 0;
                        min-width: 100%;
                        font-size: 15px;
                        line-height: 20px;
                        color: var(--text-color);
                    }
                    .descr{
                        width: 0;
                        min-width: 100%;
                        font-size: 12px;
                        line-height: 12px;
                        color: var(--primary-gray);
                    }
                }
                svg{
                    flex-shrink: 0;
                    align-self: center;
                    width: 20px;
                    height: 20px;
                }
            }
        }
    }
}

// popover--field-search-select

.popover--field-search-select{
    border-radius: 4px;
    border: none;
    box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.15);
    box-shadow: 0px 2px 14px 0px rgba(0, 0, 0, 0.15);
    background-color: #fff;
    overflow: hidden;
    left: -2px !important;
    right: -2px !important;
    top: 35px !important;
    transform: unset !important;
    width: calc(100% + 6px) !important;
    max-width: unset !important;
    .popover-arrow{
        display: none;
    }
    .popover-body{
        padding: 0;
        .popover--inner{
            .search-input-wrap{
                padding: 10px;
                border-bottom: 1px solid #E5E9EB;
            }
            .emptystate-wrap{
                padding: 10px;
                color: #b2b2b2;
            }
            .options{
                display: flex;
                flex-direction: column;
                max-height: 535px;
                overflow-y: auto;
                .option{
                    padding: 8px 10px;
                    font-size: 15px;
                    line-height: 20px;
                    position: relative;
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    text-decoration: none;
                    cursor: pointer;
                    border-bottom: 1px solid #E5E9EB;
                    &.option--checked{
                        background-color: var(--medbook-blue-5-pct);
                    }
                    &:last-of-type{
                        border-bottom: none;
                    }
                    &:hover, &:focus{
                        background-color: var(--medbook-blue-5-pct);
                    }
                    .title{
                        
                    }
                }
            }
        }
    }
}

// search field popovers
.popover--search-multiselect, .popover--search-period, .popover--search-period, .popover--search-select{
    &.popover--search-multiselect .options .option .title, &.popover--search-period .options .option .title{
        white-space: nowrap !important;
        text-overflow: ellipsis !important;
        overflow: hidden !important;
        width: 0 !important;
        min-width: calc(100% - 26px) !important;
    }
    border-radius: 4px;
    border: none;
    box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.15);
    background-color: #fff;
    min-width: 380px;
    overflow: hidden;
    .popover-arrow{
        display: none;
    }
    .popover-body{
        padding: 0;
        .popover--inner{
            .search-input-wrap{
                padding: 10px;
                border-bottom: 1px solid #E5E9EB;
            }
            .clear-wrap{
                padding: 10px;
                border-top: 1px solid #E5E9EB;
            }
            .emptystate-wrap{
                padding: 10px;
                color: #b2b2b2;
            }
            .options{
                display: flex;
                flex-direction: column;
                max-height: 610px;
                overflow-y: auto;
                .option{
                    padding: 8px 10px;
                    font-size: 15px;
                    line-height: 20px;
                    position: relative;
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    text-decoration: none;
                    cursor: pointer;
                    border-bottom: 1px solid #E5E9EB;
                    &.option--checked{
                        background-color: var(--medbook-blue-5-pct);
                    }
                    &:last-of-type{
                        border-bottom: none;
                    }
                    &:hover{
                        background-color: var(--medbook-blue-5-pct);
                    }
                    .title{
                        
                    }
                }
                .section-title{
                    padding: 12px 10px 2px 10px;
                    font-size: 15px;
                    line-height: 20px;
                    position: relative;
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    text-decoration: none;
                    cursor: pointer;
                    // border-bottom: 1px solid #E5E9EB;
                    font-weight: 500;
                    &:last-of-type{
                        border-bottom: none;
                    }
                }
            }
        }
    }
    &.popover--search-period{
        min-width: 393px;
        overflow: visible !important;
        .popover-body{
            .popover--inner{
                .options{
                    overflow-y: visible !important;
                    max-height: unset !important;
                    .option{
                        line-height: 20px;
                        app-field-single-radio{
                            margin: 2px 0;
                        }
                        .title{
                            
                        }
                    }
                }
            }
        }
        .field-date{
            .field-date-inner{
                
            }
        }
    }
}