// general
.logo{
    user-select: none;
    -webkit-user-drag: none;
}
// form & detail layout
.container{
    width: 1260px !important;
    max-width: 1260px !important;
    padding-left: 30px;
    padding-right: 30px;
    margin-inline: auto;
}
.app--public{
    .container{
        width: 100% !important;
        max-width: 1260px !important;
        padding-left: 20px !important;
        padding-right: 20px !important;
    }
}
.row--display, .row--form{
    &.row--display{
        &>div{
            margin-bottom: 20px;
        }
    }
    &.row--form{
        &>div{
            margin-bottom: 15px;
        }
    }
    .label{
        color: var(--primary-gray);
        font-size: 15px;
        line-height: 25px;
    }
    .value{
        color: var(--text-color);
        font-size: 15px;
        line-height: 25px;
    }
    &:last-of-type{
        &>div{
            margin-bottom: 0;
        }
    }
}
.form-button-row{
    display: flex;
    align-items: center;
    gap: 20px;
}
.child-form-panel{
    border-radius: 2px;
    border: 1px solid #E5E9EB;
    background: #F5FAFF;
    padding: 20px;
    margin-bottom: 20px;
    h3{
        color: var(--text-color);
        font-size: 17px;
        font-weight: 500;
        line-height: 21px;
        margin-bottom: 15px;
    }
}
.child-detail-panel{
    border-radius: 2px;
    border: 1px solid #E5E9EB;
    // background: #F5FAFF;
    padding: 20px;
    margin-bottom: 20px;
    h3{
        color: var(--text-color);
        font-size: 17px;
        font-weight: 500;
        line-height: 21px;
        margin-bottom: 15px;
    }
}
.single-radio{
    width: 16px;
    height: 16px;
    border: 1px solid var(--primary-gray);
    position: relative;
    border-radius: 99px;
    flex-shrink: 0;
    &::after{
        content: '';
        width: 10px;
        height: 10px;
        position: absolute;
        border-radius: 99px;
        background-color: var(--primary-blue);
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        opacity: 0;
    }
    &.single-radio--active{
        border-color: var(--primary-blue);
        &::after{
            opacity: 1;
        }
    }
}

// alerts
.app-alert{
    background-color: #FEF9D4;
    padding: 10px;
    border-radius: 4px;
    font-size: 15px;
    line-height: 25px;
    &.app-alert--green{
        background-color: var(--medbook-green-5-pct);
    }
    &.app-alert--orange{
        background-color: var(--medbook-orange-5-pct);
    }
}

// legacy tabs
.legacy-tabs{
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    height: 45px;
    a.tab{
        text-decoration: none;
        &.active .legacy-link-inner{
            padding-top: 5px;
            height: 44px;
            border-bottom-color: transparent;
            color: var(--primary-blue);
        }
        &:first-of-type .legacy-link-inner{
            margin-left: 0;
        }
        .legacy-link-inner{
            padding: 0px 20px;
            height: 39px;
            line-height: 38px;
            text-transform: uppercase;
            font-size: 13px;
            font-weight: 400;
            color: #afafaf;
            border: 1px solid #E5E9EB;
            background: #fff;
            margin-left: -1px;
        }
    }
}

// (new legacy) nav-tabs
.app-nav-tabs{
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    .tab{
        text-decoration: none;
        cursor: pointer;
        &.active .legacy-link-inner, &.active .inner, &.active .link-inner, &.active .inner{
            height: 36px;
            border-bottom-color: transparent;
            color: var(--primary-blue);
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
            .tab-amount-wrap{
                &>i{
                    background-color: var(--medbook-blue-10-pct);
                    color: var(--primary-blue);
                }
            }
        }
        &:first-of-type .legacy-link-inner, &:first-of-type .inner, &:first-of-type .link-inner, &:first-of-type .inner{
            margin-left: 0;
            border-top-left-radius: 4px;
        }
        &:last-of-type .legacy-link-inner, &:last-of-type .inner, &:last-of-type .link-inner, &:last-of-type .inner{
            border-top-right-radius: 4px;
        }
        .legacy-link-inner, .inner, .link-inner, .inner{
            display: flex;
            align-items: flex-end;
            height: 32px;
            padding: 0 20px;
            text-transform: uppercase;
            font-size: 12px;
            line-height: 1;
            font-weight: 500;
            border: 1px solid #E5E9EB;
            color: #91A2AF;
            background: #fff;
            margin-left: -1px;
            &>span{
                line-height: 30px;
            }
        }
        &.has-filtered-amount{
            i.amount{
                i.sep{
                    padding: 0 2px;
                    color: #91A2AF;
                }
                span.amount-num{
                    color: #91A2AF;
                }
            }
        }
        .tab-amount-wrap{
            display: flex;
            align-items: center;
            gap: 7px;
            &>span{
                
            }
            &>i{
                height: 20px;
                min-width: 20px;
                padding: 0 7px;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                background-color: var(--medbook-blue-10-pct);
                border-radius: 99px;
                color: #91A2AF;
                font-size: 10px;
                font-weight: 500;
            }
        }
    }
}

// overview emptystate
.overview-emptystate{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    border: 1px solid #E5E9EB;
    padding: 30px 20px;
    gap: 15px;
    border-radius: 4px;
    i{

    }
    .wrap{
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 5px;
        .title{
            width: 100%;
            color: var(--text-color);
            font-size: 17px;
            font-weight: 500;
            line-height: 21px;
        }
        .descr{
            width: 100%;
            color: var(--primary-gray);
            font-size: 15px;
            font-weight: 400;
            line-height: 20px;
        }
    }
}

// table loading spinner
.table-loading-spinner{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 88px 0;
    app-spinner{
        display: inline-flex;
        background-color: #fff;
        width: 40px;
        height: 40px;
        align-items: center;
        justify-content: center;
        border-radius: 99px;
    }
}

// portfolio
.portfolio-item-linked-to-parent{
    border-radius: 4px;
    border: 1px solid #E5E9EB;
    background: #fff;
    padding: 20px;
    display: inline-flex;
    gap: 20px;
    text-decoration: none;
    color: var(--text-color);
    position: relative;
    &>i{
        width: 40px !important;
        height: 40px !important;
    }
    &>.info-wrap{
        display: flex;
        flex-direction: column;
        gap: 5px;
        padding-right: 20px;
        .title{
            line-height: 20px;
        }
        .descr{
            line-height: 20px;
            font-weight: 500;
        }
        .date-stamp{
            margin-right: auto;
        }
    }
    button.unlink-from-parent{
        display: flex;
        position: absolute;
        top: 5px;
        right: 5px;
        &:hover{
            filter: brightness(.8);
        }
    }
}

// avatar
.add-avatar-wrap{
    width: 240px;
    height: 240px;
    padding: 10px;
    border-radius: 4px;
    background-color: var(--medbook-blue-5-pct);
    display: flex;
    align-items: center;
    justify-content: center;
}

.avatar-image-240{
    width: 240px;
    height: 240px;
    border-radius: 4px;
    object-fit: cover;
    object-position: center;
}

// spinner
.spinner-wrap-h-400{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 400px;
}
.spinner-wrap-h-300{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 300px;
}
.spinner-wrap-h-200{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 200px;
}

// app-list-row-form
.app-list-row-form{
    display: flex;
    flex-direction: column;
    gap: 10px;
    .app-list-row{
        display: grid;
        gap: 10px;
        // grid-template-columns: 1fr 1fr auto; // inline style
    }
    .remove-wrap{
        margin-top: 25px;
        width: 20px;
        height: 40px;
        display: flex;
        align-items: center;
        button{
            display: flex;
            padding: 10px;
            margin: -10px;
            &:hover{
                filter: brightness(.8);
            }
            &[inert]{
                opacity: .3;
                pointer-events: none;
                filter: grayscale(1);
            }
        }
    }
}

// locations
.multilang-icon-wrap{
    width: 20px;
    height: 20px;
    margin: 1px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--medbook-blue-10-pct);
    border-radius: 4px;
    cursor: pointer;
}
.selected-location-blue{
    padding: 10px;
    border-radius: 4px;
    background-color: var(--medbook-blue-5-pct);
    margin-bottom: 6px;
    .head{
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 10px;
        .title{
            font-weight: 500;
        }
        .type-stamp{
            background-color: #fff;
        }
    }
    .address{
        font-size: 13px;
        line-height: 16px;
        color: var(--primary-gray);
    }
}
.type-stamp--to-review-hover{
    transition: .1s ease;
    &:hover{
        filter: brightness(.96);
    }
}

// auth
.auth-email-check{
    height: 42px;
    border: 1px solid #E9E9E9;
    display: flex;
    align-items: center;
    border-radius: 22px;
    padding: 10px;
    padding-right: 15px;
    padding-left: 50px;
    background-image: url(/assets/img/auth/auth-check-green.svg);
    background-repeat: no-repeat;
    background-size: 20px;
    background-position: center left 20px;
    user-select: none;
    -webkit-user-drag: none;
}

// copy text + hover icon
.copy-text-hover{
    display: inline-block;
    cursor: pointer;
    padding-right: 20px;
    transition: 0.1s ease;
    background-repeat: no-repeat;
    background-size: 17px;
    background-position: top 3px right;
    background-image: url(/assets/img/icons/copy-clipboard-gray.svg);
    &:hover{
        background-image: url(/assets/img/icons/copy-clipboard-blue--admin.svg);
    }
}
table .copy-text-hover{
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
}

// info panels
.info-panel{
    display: flex;
    flex-direction: column;
    gap: 5px;
    color: var(--text-color);
    border-radius: 4px;
    padding: 10px;
    &.info-panel--orange{
        color: var(--medbook-orange);
        background-color: var(--medbook-orange-5-pct);
    }
    .head{
        display: flex;
        gap: 5px;
        i{

        }
        .title{
            line-height: 20px;
            font-weight: 500;
        }
    }
    .descr{
        color: var(--text-color);
        line-height: 20px;
        padding-left: 25px;
    }
}

// change-history
.change-history{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    .change-history-item{

        .change-head{
            display: grid;
            grid-template-columns: 1fr auto 1fr;
            gap: 10px;
            padding-bottom: 5px;
            &>div{
                line-height: 20px;
            }
        }
        .change-row{
            display: grid;
            grid-template-columns: 1fr auto 1fr;
            gap: 10px;
            padding: 10px 0;
            border-top: 1px solid #E5E9EB;
            &>div{
                line-height: 20px;
                &.linethrough{
                    
                }
                &.passive{
                    color: #8E8E8E;
                }
            }
            &>i{

            }
        }
    }
}

// assistant scores
.assistant-score{
    width: 30px;
    height: 30px;
    border-radius: 99px;
    background-color: #E9E9E9;
    color: #fff;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 15px;
    font-weight: 500;
    position: relative;
    &.assistant-score--1{
        background-color: #E3D11D;
    }
    &.assistant-score--2{
        background-color: #C1D141;
    }
    &.assistant-score--3{
        background-color: #89C55B;
    }
    &.assistant-score--4{
        background-color: #3DAC64;
    }
    &.assistant-score--5{
        background-color: #1E763D;
    }
    .icon-verified-badge{
        position: absolute;
        top: -7px;
        right: -7px;
    }
}