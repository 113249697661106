// label
.field .label, .standalone-label {
    color: var(--primary-gray);
    font-size: 15px;
    line-height: 25px;
    &.standalone-label--auth{
        font-size: 15px;
        line-height: 25px;
        margin: 0;
        font-weight: 400;
        color: #91A2AF;
    }
    span.optional{
        color: #bbbbbb;
        font-weight: 400;
        margin-left: 4px;
        font-size: 13px;
    }
}

// error
.field {
    .after-string{
        font-size: 15px;
        line-height: 20px;
        white-space: nowrap;
    }
    .error-field{
        border: 1px solid var(--medbook-red) !important;
    }
    .error-field-display {
        display: block;
        font-size: 15px;
        line-height: 20px;
        margin-top: 2px;
        color: var(--medbook-red);
    }
}
.standalone-error-field{
    border: 1px solid var(--medbook-red) !important;
}
.standalone-error {
    display: block;
    font-size: 15px;
    line-height: 20px;
    margin-top: 2px;
    color: var(--medbook-red);
}

// default placeholder
::placeholder {
    color: #BCBCBD;
    // color: var(--primary-gray);
    opacity: 1;
    font-weight: 400;
}

// ngb datepicker
body{
    ngb-datepicker{
        box-shadow: 0px 2px 8px 0px rgba(53, 84, 119, 0.2);
        border: none !important;
        border-radius: 8px;
        z-index: 1990 !important;
        padding: 5px !important;
    }
    ngb-datepicker-navigation{
        padding-left: 5px;
        padding-right: 5px;
    }
    .ngb-dp-day, .ngb-dp-weekday, .ngb-dp-week-number {
        // width: 38px;
        // height: 38px;
        width: 42px;
        height: 34px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .ngb-dp-header{
        position: relative;
        padding-bottom: 10px;
        padding-top: 10px;
        // &::after{
        //     content: '';
        //     position: absolute;
        //     height: 1px;
        //     background-color: #D2D9D6;
        //     left: 12px;
        //     right: 12px;
        //     bottom: 0;
        // }
    }
    .ngb-dp-navigation-select{
        display: flex;
        gap: 10px;
        .form-select{
            &:first-of-type{
                    // border: 0 !important;
                    // background: none !important;
                    outline: none !important;
                    // text-align: right !important;
                    // font-weight: 500 !important;
                    color: var(--text-color) !important;
                    box-shadow: none !important;
                    font-size: 15px !important;
                    border-radius: 4px !important;
                    height: 30px;
                    padding: 0 10px;
                    padding-right: 30px;
                    background-position: right 10px center;
                    border: 1px solid var(--field-border-color);
                    // padding-right: 2px !important;
                    text-transform: capitalize !important;
            }
            &:last-of-type{
                    // border: 0 !important;
                    // background: none !important;
                    font-size: 15px !important;
                    border-radius: 4px !important;
                    height: 30px;
                    padding: 0 10px;
                    padding-right: 30px;
                    background-position: right 10px center;
                    border: 1px solid var(--field-border-color);
                    outline: none !important;
                    // text-align: left !important;
                    // font-weight: 500 !important;
                    box-shadow: none !important;
                    color: var(--text-color) !important;
                    // padding-left: 2px !important;
            }
        }
    }
    .ngb-dp-arrow{
        &:first-of-type{
            .ngb-dp-navigation-chevron{
                width: 7px !important;
                height: 7px !important;
                border-width: 1px 1px 0 0 !important;
                display: block !important;
                color: var(--primary-blue);
            }
        }
        &:last-of-type{
            .ngb-dp-navigation-chevron{
                width: 7px !important;
                height: 7px !important;
                border-width: 1px 1px 0 0 !important;
                display: block !important;
                color: var(--primary-blue);
            }
        }
        .ngb-dp-arrow-btn{
            display: flex;
            margin: 0;
            padding: 0;
            align-items: center;
            justify-content: center;
            width: 30px;
            height: 30px;
            border-radius: 99px;
            transition: .1s ease;
            &:hover{
                background-color: #F5FAFF;
            }
        }
    }
    .ngb-dp-header{
        background-color: transparent !important;
    }
    .ngb-dp-weekdays{
        background-color: transparent !important;
        border-bottom: none !important;
        cursor: default;
    }
    .bg-primary{
        background-color: var(--primary-blue) !important;
        outline: none !important;
    }
    .ngb-dp-weekday{
        color: var(--primary-gray) !important;
        font-style: normal !important;
        font-weight: 600 !important;
        font-size: 12px !important;
    }
    .ngb-dp-day{
        display: flex;
        align-items: center;
        justify-content: center;
        &.ngb-dp-today{
            .btn-light{
                outline: 1px solid var(--bs-border-color);
            }
        }
        .btn-light{
            font-size: 15px !important;
            font-weight: 500 !important;
            // border-radius: 99px;
            // width: 28px;
            // height: 28px;
            width: 38px;
            height: 28px;
            align-self: center;
            line-height: 28px;
            &.text-muted{
                color: #c9d5db !important;
            }
            &:hover{
                background-color: #F5FAFF;
                color: var(--primary-blue);
                border: none !important;
                outline: none !important;
            }
        }
    }
}

// field typeahead
.field-typeahead{
    .typeahead-wrap{
        position: relative;
        &>button{
            position: absolute;
            right: 10px;
            top: 50%;
            transform: translateY(-50%);
            z-index: 1;
            display: flex;
            width: 20px;
            height: 20px;
            &:hover{
                filter: brightness(.8);
            }
            i.icon-close-typeahead{
                flex-shrink: 0;
                display: inline-block;
                background-repeat: no-repeat;
                background-size: contain;
                background-position: center;
                background-image: url(/assets/img/components/field-typeahead/close-typeahead.svg);
                width: 20px;
                height: 20px;
            }
        }
    }
    &.field-typeahead--filled{
        input{
            background: transparent;
        }
    }
    input{
        font-size: 15px;
        user-select: none;
        display: block;
        width: 100%;
        padding: 0 10px;
        border: 1px solid var(--field-border-color);
        border-radius: 4px;
        &:focus{outline: var(--default-outline);}
        background-color: #fff;
        height: 40px;
        -webkit-appearance: none;
        appearance: none;
        background: #fff url(/assets/img/components/field-typeahead/search.svg) right 10px center no-repeat;
        background-size: 25px;
        padding-right: 40px;
        color: var(--text-color);
    }
    .ngb-highlight{
        font-weight: 600;
    }
    .dropdown-menu{
        padding: 0;
        border-radius: 0;
        width: 100%;
        max-height: 500px;
        overflow-y: auto;
        margin-top: 5px !important;
        border-radius: 4px;
        border: none;
        box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.15);
        background-color: #fff;
        .dropdown-item{
            white-space: normal;
            padding: 8px 10px;
            font-size: 15px;
            line-height: 20px;
            position: relative;
            display: flex;
            align-items: center;
            text-decoration: none;
            color: var(--text-color);
            cursor: pointer;
            border-bottom: 1px solid #E5E9EB;
            &:last-of-type{
                border-bottom: none;
            }
            &:hover, &.active{
                background-color: var(--medbook-blue-5-pct);
                color: var(--text-color);
            }
        }
    }
    .typeahead-wrap--locations{
        &.typeahead-wrap--loading{
            input{
                background: #fff !important;
            }
            .clear-button{
                display: none !important;
            }
        }
        .dropdown-menu{
            .dropdown-item{
                display: flex;
                flex-direction: column;
                align-items: unset;
                gap: 5px;
                padding: 15px 10px;
                .head{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    gap: 10px;
                    .title{
                        font-weight: 500;
                    }
                }
                .foot{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    gap: 10px;
                    .address{
                        font-size: 13px;
                        line-height: 15px;
                        color: var(--primary-gray);
                    }
                    .score-match{
                        margin-left: auto;
                    }
                }
                .link{
                    margin-right: auto;
                }
            }
        }
    }
}